//libraries
import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import toastr from "toastr";
import cookie from "js-cookie";
import decode from "jwt-decode";

//css
import "toastr/build/toastr.min.css";
import "./datatables.scss";

//components
import Breadcrumbs from "../../components/Common/Breadcrumb";
import StatusDisplay from "../../components/StatusDisplay/StatusDisplay";

//data
import countryNames from "../../data/countryNames";

//funs
import authReq from "funs/authReq";

//token
let token = cookie.get("token") || "";
let userData = token ? decode(token) : {};

//render component
function AddGroup() {
  //states
  const [modal, setModal] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);
  const [referenceIdGenerated, setReferenceIdGenerated] = useState("");

  //useEffects
  useEffect(() => {
    codeGeneration();
  }, []);
  useEffect(() => {
    getData();
  }, []);

  //funs inside
  async function codeGeneration() {
    // console.log("inside codeGeneration");
    authReq("generate-reference-id").then(({ data }) => {
      if (data.success == true) {
        setReferenceIdGenerated(data.referenceId);
      }
    });
  }
  const grpCrtEditForm = (e) => {
    e.preventDefault();

    //get reference id and corresponding tracking ids
    let trackingIds = newData.rows.map((item) => {
      return item.trackingId;
    });
    let datatoSend = { referenceId: referenceIdGenerated, trackingIds };
    console.log(datatoSend.trackingIds.length)
    if(datatoSend.trackingIds.length<2){
      toastr.error("Add atleast two shipments to make group");
    }else{
     authReq("group-shipments", datatoSend).then((res) => {
      if (res.data.success == true) {
        toastr.success("Grouped successfuly", "Success");
        setTimeout(() => {
          setNewData((prev) => {
            return { ...prev, rows: [] };
          });
          setReferenceIdGenerated("");
          codeGeneration();
          getData();
        }, 1000);
      } else if (res.data.success == false) {
        toastr.error(res.data.message, "Error");
      }
    }); 
    }
    
  };
  const addToGroup = (item) => {
    console.log(item)
    item.remove = (
      <div className="downloadButtonDiv" onClick={() => removeFromGroup(item)}>
        Remove
      </div>
    );

    //add to group
    setNewData((prev) => {
      return { ...prev, rows: [...prev.rows, item] };
    });

    //remove from all data
    setData((prev) => {
      let newRows = prev.rows.filter((x) => {
        return x.trackingId != item.trackingId;
      });
      return { ...prev, rows: newRows };
    });
  };
  const removeFromGroup = (item) => {
    //add to all data
    setData((prev) => {
      return { ...prev, rows: [item, ...prev.rows] };
    });
    //remove from group
    setNewData((prev) => {
      let newRows = prev.rows.filter((x) => {
        return x.trackingId != item.trackingId;
      });
      return { ...prev, rows: newRows };
    });
  };
  const getData = () => {
    authReq("get-shipments").then(({ data }) => {
      // console.log(data);
      let length = data.data.length;
      console.log(data.data)

      let dt = data.data?.map((item, index) => {
        item.index = length - index;

        if (item.referenceId.length == 0) {
          item.referenceId = "-";
        } else {
          item.referenceId = item.referenceId.join(", ");
        }
        item.invoice = (
          <div
            className="downloadButtonDiv"
            onClick={() => {
              addToGroup(item);
            }}
          >
            Add
          </div>
        );

        item.status = <StatusDisplay value={item.status} />;

        item.origin = countryNames.filter(
          (x) => x.value == item.origin
        )[0]?.label;
        item.destination = countryNames.filter(
          (x) => x.value == item.destination
        )[0]?.label;

        item.createdAt = new Date(item.createdAt).toLocaleDateString();
        if (!item.referenceId) {
          item.referenceId = "-";
        }

        return item;
      });

      setData((prev) => {
        return { ...prev, rows: dt };
      });
    });
  };

  const submit = (e) => {
    e.preventDefault();
    // console.log("clic");
    let data = {
      name: document.modalbody.name.value,
      host: document.modalbody.host.value,
      username: document.modalbody.username.value,
      password: document.modalbody.password.value,
    };
    authReq("add-server", data).then((res) => {
      // console.log(res.data)
      toastr.success("Added successfuly", "Success");
      setModal(false);
      getData();
    });
  };
  const tog_modal = () => {
    setModal((prev) => !prev);
  };

  const [newData, setNewData] = useState({
    columns: [
      {
        label: "Tracking Id",
        field: "trackingId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Group Id(s)",
        field: "referenceId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "createdAt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Sender",
        field: "from",
        sort: "asc",
        width: 100,
      },
      {
        label: "Receiver",
        field: "to",
        sort: "asc",
        width: 100,
      },
      {
        label: "Origin",
        field: "origin",
        sort: "asc",
        width: 100,
      },
      {
        label: "Destination",
        field: "destination",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "remove",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });
  const [data, setData] = useState({
    columns: [
      {
        label: "Tracking Id",
        field: "trackingId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Group Id(s)",
        field: "referenceId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "createdAt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Sender",
        field: "from",
        sort: "asc",
        width: 100,
      },
      {
        label: "Receiver",
        field: "to",
        sort: "asc",
        width: 100,
      },
      {
        label: "Origin",
        field: "origin",
        sort: "asc",
        width: 100,
      },
      {
        label: "Destination",
        field: "destination",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "invoice",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Management" breadcrumbItem="Create Grouping" />
          {userData.role > 1 && (
            <Row className="mb-2">
              <Col sm="12">
                <div className="text-sm-right">
                  <Button
                    onClick={tog_modal}
                    style={{ display: "none" }}
                    type="button"
                    color="success"
                    className="btn-rounded waves-effect waves-light mb-2 mr-2"
                  >
                    <i className="mdi mdi-plus mr-1"></i> Add Server
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-12">
              {/* <Card>
                <CardBody>
                  <form onSubmit={grpCrtEditForm}>
                    <h6 htmlFor="referenceId">ReferenceId</h6>
                    <input
                      type="text"
                      id="referenceId"
                      name="referenceId"
                      value={referenceIdGenerated}
                      placeholder="reference id"
                      required
                      className="createGroupTextbox"
                      onChange={(e) => setReferenceIdGenerated(e.target.value)}
                    />
                    <button
                      type="submit"
                      id="createGp"
                      className="createGroupButton float-md-right"
                      disabled={newData.rows.length <= 0 ? true : false}
                    >
                      Create Group
                    </button>
                  </form> 
                </CardBody>
              </Card> */}
              <Card className={optionSelected ? "" : "dispNone"}>
                <CardBody>
                  <h4 className="custom-form-grouping-header">New group</h4>
                  <form onSubmit={grpCrtEditForm}  style={{padding:"10px 0px 20px 0px"}}>
                    <h6 htmlFor="referenceId">Group Id</h6>
                    <input
                      type="text"
                      id="referenceId"
                      name="referenceId"
                      value={referenceIdGenerated}
                      placeholder="group id"
                      required
                      className="createGroupTextbox"
                      onChange={(e) => setReferenceIdGenerated(e.target.value)}
                    />
                    <button
                      type="submit"
                      id="createGp"
                      className="createGroupButton float-md-right"
                      disabled={newData.rows.length <= 0 ? true : false}
                    >
                      Create Group
                    </button>
                  </form>
                  <MDBDataTable responsive striped bordered data={newData} />
                </CardBody>
              </Card>
              <Card className={optionSelected ? "" : "dispNone"}>
                <CardBody>
                  <h4 className="custom-form-grouping-header">All shipments</h4>

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
      <Modal
        size="md"
        isOpen={modal}
        toggle={tog_modal}
        scrollable={true}
        // centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Server</h5>
          <button
            type="button"
            onClick={tog_modal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form name="modalbody" onSubmit={submit}>
            <FormGroup>
              <Label htmlFor="name">Server Name</Label>
              <Input
                id="name"
                name="name"
                type="text"
                className="form-control"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="host">Host</Label>
              <Input
                id="host"
                name="host"
                type="text"
                className="form-control"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="username">Username</Label>
              <Input
                id="username"
                name="username"
                type="text"
                className="form-control"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                name="password"
                type="text"
                className="form-control"
              />
            </FormGroup>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={tog_modal}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default AddGroup;
