import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button

} from "reactstrap";
import authReq from "funs/authReq";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import "./datatables.scss";
import { text } from "body-parser";
function BulkShipment() {

  const [selectedFile, setSelectedFile] = useState()
  const [errorTxt, setErrorTxt] = useState([])
  const [errorModal, setErrorModal] = useState(false)
  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }
  const toggleErrorModal = () => {
    setErrorModal(prev => !prev)
  }
  const bulkShipmentSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(
      "bulkShipment",
      selectedFile,
      selectedFile.name
    );

    authReq("add-bulk-shipment", formData).then((res) => {
      if (res.data.success === true) {
        toastr.success("Added successfuly", "Success");

      }
      else if (res.data.success === false && res.data.message) {
        toastr.error(res.data.message, "Error");
      }
      else if (res.data.success === false && res.data.errorMessages.length > 0) {
        setErrorTxt(res.data.errorMessages)
        setErrorModal(true)
      }
    })
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Card><CardBody>
            <Form name="shipment" onSubmit={bulkShipmentSubmit}>
              <FormGroup>
                <h4 className="custom-form-grouping-header">Add Bulk Shipments</h4>
                <Input type="file" name="file" id="exampleFile" required onChange={onFileChange} />
              </FormGroup>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ cursor: "pointer" }}
                  id="save"
                > Submit
                </button>
              </div>

            </Form>
          </CardBody>
          </Card>

        </div>
        <Modal isOpen={errorModal} toggle={toggleErrorModal}>
          <ModalHeader toggle={toggleErrorModal}>Errors in file</ModalHeader>
          <ModalBody>
            <ol>{errorTxt?.map(txt => (<li>{txt}</li>))}</ol></ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                setErrorModal(false);

              }}
            >
              Ok
            </Button>{" "}
            {/* <Button color="secondary" onClick={toggleErrorModal}>
              Cancel
            </Button> */}
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
}
export default BulkShipment;