import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Shipment from "../pages/Shipment/Shipment";
import Configuration from "../pages/Configurations/Configuration";
import AddShipment from "../pages/AddShipment/AddShipment";
import AddGroup from "../pages/AddGroup/AddGroup";
import editShipment from "../pages/EditShipment/EditShipment";
import EditGroup from "../pages/EditGroup/EditGroup";
import GroupList from "../pages/GroupList/GroupList";
import RefStatusUpdate from "../pages/RefStatusUpdate/RefStatusUpdate";
import TrackStatusUpdate from "../pages/TrackStatusUpdate/TrackStatusUpdate";
import BulkShipment from "../pages/BulkShipment/BulkShipment";

const authProtectedRoutes = [

  { path: "/dashboard", component: Dashboard },
  { path: "/configuration", component: Configuration },
  { path: "/shipments", component: Shipment },
  { path: "/add-shipment", component: AddShipment },
  { path: "/add-bulk-shipments", component: BulkShipment },
  { path: "/edit-shipment/:trackingId", component: editShipment },
  { path: "/add-group", component: AddGroup },
  { path: "/edit-group/:referenceId", component: EditGroup },
  { path: "/list-group", component: GroupList },
  {
    path: "/update-status-group/:referenceId",
    exact: true,
    component: RefStatusUpdate,
  },
  { path: "/update-status-group/", component: RefStatusUpdate },
  {
    path: "/update-status-shipment/:trackingId",
    exact: true,
    component: TrackStatusUpdate,
  },
  { path: "/update-status-shipment/", component: TrackStatusUpdate },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
