import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";

import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import toastr from "toastr";
import countryNames from "../../data/countryNames";
import modeOptions from "../../data/modeOptions";
import typeOptions from "../../data/typeOptions";

import "toastr/build/toastr.min.css";
import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import cookie from "js-cookie";
import decode from "jwt-decode";
import Select from "../../components/CustomSelect/CustomSelect";
import authReq from "funs/authReq";
let token = cookie.get("token") || "";
let userData = token ? decode(token) : {};

function EditShipment() {
  const [trackingIdGenerated, setTrackingIdGenerated] = useState("");
  const [maxDate, setMaxDate] = useState(null);

  const [receivedAtDate, setReceivedAtDate] = useState();
  const [selectedTypeOption, setSelectedTypeOption] = useState();
  const [selectedModeOption, setSelectedModeOption] = useState();

  const [selectedOriginOption, setSelectedOriginOption] = useState();
  const [selectedDestinationOption, setSelectedDestinationOption] = useState();

  let history = useHistory();

  //get tracking id from url
  const { trackingId } = useParams();

  useEffect(() => {
    let dataTosend = { trackingId: trackingId };
    //get details of the corresponding shipment
    authReq("get-shipment", dataTosend).then((res) => {
      if (res.data.success == false) {
        // history.push("/shipments")
        toastr.error("Cannot fetch data", "Error");

        return
      }
      if (res.data.success == true) {
        //populate it in the fields
        let {
          trackingId,
          from,
          fromPhone,
          to,
          toPhone,
          productAndQuantity,
          weight,
          shipmentMode,
          shipmentType,
          noOfPackages,
          origin,
          destination,
          receivedAt,
          comments,
        } = res.data.data;
        document.shipment.trackingId.value = trackingId;
        document.shipment.from.value = from;
        document.shipment.fromPhone.value = fromPhone;
        document.shipment.to.value = to;
        document.shipment.toPhone.value = toPhone;
        document.shipment.productAndQuantity.value = productAndQuantity;
        document.shipment.weight.value = weight;
        document.shipment.noOfPackages.value = noOfPackages;
        document.shipment.shipmentMode.value = shipmentMode;
        document.shipment.shipmentType.value = shipmentType;
        document.shipment.origin.value = origin;
        document.shipment.destination.value = destination;
        document.shipment.receivedAt.value =
          moment(receivedAt).format("YYYY-MM-DD");
        document.shipment.estimatedDeliveryTime.value = ''
        if (res.data.data.estimatedDeliveryTime) {
          document.shipment.estimatedDeliveryTime.value =
            moment(res.data.data.estimatedDeliveryTime).format("YYYY-MM-DD");
        }

        // setReceivedAtDate(moment(receivedAt).format("YYYY-MM-DD"))
        document.shipment.comments.value = comments;
        // console.log(typeOptions.filter((x) => x.value == shipmentType)[0]);
        setSelectedTypeOption(
          typeOptions.filter((x) => x.value == shipmentType)[0]
        );
        setSelectedModeOption(
          modeOptions.filter((x) => x.value == shipmentMode)[0]
        );
        // console.log(countryNames.filter((x) => x.value == origin)[0]);
        setSelectedOriginOption(
          countryNames.filter((x) => x.value == origin)[0]
        );
        setSelectedDestinationOption(
          countryNames.filter((x) => x.value == destination)[0]
        );
      }
    });
  }, []);
  useEffect(() => {
    setMaxDate(moment().format("YYYY-MM-DD"));
  }, []);
  const editShipmentSubmit = (e) => {
    e.preventDefault();
    let data = {
      trackingId: document.shipment.trackingId.value,
      from: document.shipment.from.value,
      fromPhone: document.shipment.fromPhone.value,
      to: document.shipment.to.value,
      toPhone: document.shipment.toPhone.value,
      productAndQuantity: document.shipment.productAndQuantity.value,
      weight: document.shipment.weight.value,
      noOfPackages: document.shipment.noOfPackages.value,
      shipmentMode: document.shipment.shipmentMode.value,
      shipmentType: document.shipment.shipmentType.value,
      origin: document.shipment.origin.value,
      destination: document.shipment.destination.value,
      receivedAt: document.shipment.receivedAt.value,
      estimatedDeliveryTime:document.shipment.estimatedDeliveryTime.value,
      comments: document.shipment.comments.value,
    };
    // console.log(data);
    authReq("edit-shipment", data).then((res) => {
      // console.log(res.data);
      if (res.data.success == true) {
        toastr.success("Edited successfuly", "Success");
        setTimeout(() => {
          history.push("/shipments");
        }, 1000);
      } else if (res.data.success == false) {
        toastr.error(res.data.message, "Error");
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Management" breadcrumbItem="Edit Shipment" /> */}
          <Form name="shipment" onSubmit={editShipmentSubmit}>
            <div className="custom-form-tracking-grouping">
              <h4 className="custom-form-grouping-header">Tracking Details</h4>
              <div className="row">
                <FormGroup className="col-md-6">
                  <Label htmlFor="trackingId">Tracking Id</Label>
                  <Input
                    id="trackingId"
                    name="trackingId"
                    type="text"
                    className="form-control"
                    defaultValue={trackingIdGenerated}
                    onChange={(e) => {
                      setTrackingIdGenerated(e.target.value);
                    }}
                    disabled={true}
                    required
                  />
                </FormGroup>
              </div>
            </div>
            <div className="custom-form-send-receiver-grouping">
              <h4 className="custom-form-grouping-header">
                Sender / Receiver details
              </h4>
              <div className="row">
                {" "}
                <div className="col-md-6">
                  <FormGroup>
                    <Label htmlFor="from">Sender Address</Label>
                    <Input
                      id="from"
                      name="from"
                      type="textarea"
                      className="form-control"
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="fromPhone">Sender Phone No.</Label>
                    <Input
                      id="fromPhone"
                      name="fromPhone"
                      type="text"
                      className="form-control"
                      required
                      max="999999999999999"
                      maxLength="15"
                      onKeyDown={(e) => {
                        if (
                          e.target.value.length == 15 &&
                          e.key >= 0 &&
                          e.key <= 9
                        ) {
                          e.preventDefault();
                        }
                        if (e.key == "ArrowUp" || e.key == "ArrowDown")
                          e.preventDefault();
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup>
                    <Label htmlFor="to">Receiver Address</Label>
                    <Input
                      id="to"
                      name="to"
                      type="textarea"
                      className="form-control"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="toPhone">Receiver Phone No.</Label>
                    <Input
                      id="toPhone"
                      name="toPhone"
                      type="text"
                      className="form-control"
                      required
                      max="999999999999999"
                      maxLength="15"
                      onKeyDown={(e) => {
                        if (
                          e.target.value.length == 15 &&
                          e.key >= 0 &&
                          e.key <= 9
                        ) {
                          e.preventDefault();
                        }
                        if (e.key == "ArrowUp" || e.key == "ArrowDown")
                          e.preventDefault();
                      }}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="custom-form-grouping">
              <h4 className="custom-form-grouping-header">Shipment details</h4>
              {/* <div className="custom-form-section1 "> */}
              <div className="row">
                <FormGroup className="col-md-6">
                  <Label htmlFor="productAndQuantity">
                    Products & Quantity
                  </Label>
                  <Input
                    id="productAndQuantity"
                    name="productAndQuantity"
                    type="textarea"
                    className="form-control"
                    required
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="weight">Weight</Label>
                  <Input
                    id="weight"
                    name="weight"
                    type="text"
                    className="form-control"
                    required
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="no">No. of Packages</Label>
                  <Input
                    id="noOfPackages"
                    name="noOfPackages"
                    type="text"
                    className="form-control"
                    required
                    pattern="[0-9]+"
                  />
                </FormGroup>
              </div>

              <div className="row">
                <FormGroup className="col-md-3">
                  <Label htmlFor="modeofShipment">Mode of Shipment</Label>
                  <Select
                    name="shipmentMode"
                    id="shipmentMode"
                    value={selectedModeOption}
                    onChange={setSelectedModeOption}
                    required
                    options={modeOptions}
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="shipmentType">Type of Shipment</Label>
                  <Select
                    value={selectedTypeOption}
                    onChange={setSelectedTypeOption}
                    name="shipmentType"
                    id="shipmentType"
                    required
                    options={typeOptions}
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="origin">Origin</Label>

                  <Select
                    value={selectedOriginOption}
                    onChange={setSelectedOriginOption}
                    options={countryNames}
                    name="origin"
                    id="origin"
                    required
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="origin">Destination</Label>
                  <Select
                    value={selectedDestinationOption}
                    onChange={setSelectedDestinationOption}
                    options={countryNames}
                    name="destination"
                    id="destination"
                    required
                  />
                </FormGroup>
              </div>
              <div className="row">
                <FormGroup className="col-md-3">
                  <Label htmlFor="receivedAt">Date of Shipment Received</Label>
                  <Input
                    id="receivedAt"
                    name="receivedAt"
                    type="date"
                    className="form-control"
                    required
                    max={maxDate}
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="estimatedDeliveryTime">Estimated Delivery Time</Label>
                  <Input
                    id="estimatedDeliveryTime"
                    name="estimatedDeliveryTime"
                    type="date"
                    className="form-control"
                    required
                    min={maxDate}
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label htmlFor="comments">Comments</Label>
                  <Input
                    id="comments"
                    name="comments"
                    type="textarea"
                    className="form-control"
                    required
                    rows="1"
                  />
                </FormGroup>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                Edit Shipment
              </button>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditShipment;
