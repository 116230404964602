import React, { useState, useEffect, useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";

import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
//icons
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlinePublishedWithChanges } from "react-icons/md";

import toastr from "toastr";
import Select from "react-select";
import { CSVLink, CSVDownload } from "react-csv";
import "toastr/build/toastr.min.css";
import moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Import  StatusDisplay
import StatusDisplay from "../../components/StatusDisplay/StatusDisplay";

import "./datatables.scss";
import cookie from "js-cookie";
import decode from "jwt-decode";
import countryNames from "../../data/countryNames";
import { Link } from "react-router-dom";

import authReq from "funs/authReq";
import { updateShorthandPropertyAssignment } from "typescript";

let token = cookie.get("token") || "";
let userData = token ? decode(token) : {};
function ProductPage() {
  const [csvData, setCsvData] = useState([]);
  const [modal, setModal] = useState(false);
  const shipmentRef = useRef("");

  const [editOrAddModal, setEditOrAddModal] = useState(false); //false-add, true-edit
  const [maxDate, setMaxDate] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const toggleConfirmDialog = () => {
    setConfirmDialog((prev) => {
      prev = !prev;
    });
  };
  const [deleteTrackingId, setDeleteTrackingId] = useState();
  useEffect(() => {
    setMaxDate(moment().format("YYYY-MM-DD"));
  }, []);
  //tracking id generation
  const [trackingIdGenerated, setTrackingIdGenerated] = useState("");
  async function codeGeneration() {
    // console.log("inside codeGeneration");
    authReq("generate-tracking-id").then(({ data }) => {
      if (data.success == true) {
        setTrackingIdGenerated(data.trackingId);
      }
    });
  }
  const getData = () => {
    authReq("get-shipments").then(({ data }) => {
      // console.log(data.data);

      let n = [
        ["shipment Code", "Status", "Model ID", "Batch no", "Created at"],
      ];
      let length = data.data.length;
      let dt = data.data.map((item, index) => {
        item.index = length - index;

        item.status = <StatusDisplay value={item.status} />;

        item.createdAt = new Date(item.createdAt).toLocaleDateString();
        item.receivedAt = new Date(item.receivedAt).toLocaleDateString();

        if (item.referenceId.length == 0) {
          item.referenceId = "-";
        } else {
          item.referenceId = item.referenceId.join(", ");
        }
        n.push([
          item.shipment,
          item.status,
          item.modelName,
          item.batchNo,
          item.createdAt,
        ]);

        item.origin = countryNames.filter(
          (x) => x.value == item.origin
        )[0]?.label;
        item.destination = countryNames.filter(
          (x) => x.value == item.destination
        )[0]?.label;

        item.actions = (
          <div
            className="row justify-content-space-evenly
          "
          >
            <Link
              id={"editButton" + index}
              to={`/edit-shipment/${item.trackingId}`}
              className="text-center"
              style={{
                cursor: "pointer",
                color: "white",
                background: "#2ca579",
                borderRadius: "50%",
                margin: "1px",

                padding: "2px",
              }}
            >
              <BiEditAlt style={{ width: "24px", padding: "5px" }} size="1x" />
            </Link>
            <UncontrolledTooltip
              placement="bottom"
              target={"editButton" + index}
            >
              Edit
            </UncontrolledTooltip>

            <Link
              id={"updateButton" + index}
              to={`/update-status-shipment/${item.trackingId}`}
              className="text-center"
              style={{
                cursor: "pointer",
                background: "#7587e0",
                color: "white",
                borderRadius: "50%",
                margin: "1px",

                padding: "2px",
              }}
            >
              <MdOutlinePublishedWithChanges
                style={{ width: "24px", padding: "5px" }}
                size="1x"
              />
            </Link>
            <UncontrolledTooltip
              placement="bottom"
              target={"updateButton" + index}
            >
              Update
            </UncontrolledTooltip>

            <span
              id={"deleteButton" + index}
              className=""
              style={{
                cursor: "pointer",
                background: "#F14646",
                color: "white",
                borderRadius: "50%",
                margin: "1px",

                padding: "2px",
              }}
              onClick={() => {
                setConfirmDialog(true);
                setDeleteTrackingId(item.trackingId);
              }}
            >
              <RiDeleteBin6Line
                style={{ width: "24px", padding: "5px" }}
                size="1x"
              />
            </span>
            <UncontrolledTooltip
              placement="bottom"
              target={"deleteButton" + index}
            >
              Delete
            </UncontrolledTooltip>
          </div>
        );
        if (item.expDate)
          item.expDate = moment(item.expDate).format("DD/MM/YYYY hh:MM a");
        return item;
      });
      setCsvData(n);
      // console.log(dt);
      setData((prev) => {
        return { ...prev, rows: dt };
      });
    });
  };

  useEffect(() => {
    getData();
  }, []);
  const submit = (e) => {
    e.preventDefault();
    // console.log("clic");
    // console.log("trackking id:", document.shipment.trackingId.value);
    let data = {
      trackingId: document.shipment.trackingId.value,
      from: document.shipment.from.value,
      fromPhone: document.shipment.fromPhone.value,
      to: document.shipment.to.value,
      toPhone: document.shipment.toPhone.value,
      productAndQuantity: document.shipment.productAndQuantity.value,
      weight: document.shipment.weight.value,
      noOfPackages: document.shipment.noOfPackages.value,
      shipmentMode: document.shipment.shipmentMode.value,
      shipmentType: document.shipment.shipmentType.value,
      noOfPackages: document.shipment.noOfPackages.value,
      origin: document.shipment.origin.value,
      destination: document.shipment.destination.value,
      receivedAt: document.shipment.receivedAt.value,
      comments: document.shipment.comments.value,
    };

    authReq(editOrAddModal ? "edit-shipment" : "add-shipment", data).then(
      (res) => {
        // console.log(res.data);
        if (res.data.success == true) {
          if (editOrAddModal) {
            toastr.success("Edited successfuly", "Success");
          } else {
            toastr.success("Added successfuly", "Success");
          }
          setModal(false);
          getData();
        } else if (res.data.success == false) {
          if (editOrAddModal) {
            toastr.error(res.data.message, "Error");
          } else {
            toastr.error(res.data.message, "Error");
          }
        }
      }
    );
  };
  const tog_modal = () => {
    setModal((prev) => !prev);
    setTrackingIdGenerated("");
    shipmentRef.current = "";
  };
  const [data, setData] = useState({
    columns: [
      {
        label: "Tracking Id",
        field: "trackingId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Group Id(s)",
        field: "referenceId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "receivedAt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Sender",
        field: "from",
        sort: "asc",
        width: 100,
      },
      {
        label: "Receiver",
        field: "to",
        sort: "asc",
        width: 100,
      },
      {
        label: "Origin",
        field: "origin",
        sort: "asc",
        width: 100,
      },
      {
        label: "Destination",
        field: "destination",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  const options = {
    title: "Title",
    message: "Message",
    buttons: [
      {
        label: "Yes",
        onClick: () => alert("Click Yes"),
      },
      {
        label: "No",
        onClick: () => alert("Click No"),
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };

  // confirmAlert(options);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Management" breadcrumbItem="Shipments" />
          {userData.role > 1 && (
            <Row className="mb-2">
              <Col sm="12">
                <div className="text-sm-right">
                  <Link to="/add-shipment">
                    <Button
                      onClick={() => {
                        // tog_modal();
                        // codeGeneration();
                        // setEditOrAddModal(false);
                      }}
                      type="button"
                      color="success"
                      className="btn-rounded waves-effect waves-light mb-2 mr-2"
                    >
                      <i className="mdi mdi-plus mr-1"></i> Add Shipment
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* delete confirmation */}
      <Modal isOpen={confirmDialog} toggle={toggleConfirmDialog}>
        <ModalHeader toggle={toggleConfirmDialog}>Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to delete the shipment?</ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              setConfirmDialog(false);
              authReq("delete-shipment", { trackingId: deleteTrackingId }).then(
                (res) => {
                  // console.log(res.data)
                  if (res.data.success == true) {
                    toastr.success("Deleted successfuly", "Success");
                    getData();
                  }
                }
              );
            }}
          >
            Yes Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggleConfirmDialog}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={tog_modal}
        scrollable={true}
        // centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {editOrAddModal ? "Edit Shipment" : "Add Shipment"}
          </h5>
          <button
            type="button"
            onClick={tog_modal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form name="shipment" onSubmit={submit}>
            <div className="custom-form-grouping">
              <h4 className="custom-form-grouping-header">Tracking Details</h4>
              <FormGroup>
                <Label htmlFor="trackingId">Tracking Id</Label>
                <Input
                  id="trackingId"
                  name="trackingId"
                  type="text"
                  className="form-control"
                  defaultValue={trackingIdGenerated}
                  onChange={(e) => {
                    setTrackingIdGenerated(e.target.value);
                  }}
                  required
                  disabled={editOrAddModal}
                />
              </FormGroup>
            </div>
            <div className="custom-form-grouping">
              <h4 className="custom-form-grouping-header">
                Sender / Receiver details
              </h4>
              <div className="custom-receiver-details">
                <FormGroup>
                  <Label htmlFor="from">Sender Address</Label>
                  <Input
                    id="from"
                    name="from"
                    type="textarea"
                    className="form-control"
                    required
                    defaultValue={
                      editOrAddModal ? shipmentRef.current.from : ""
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="fromPhone">Sender Phone No.</Label>
                  <Input
                    id="fromPhone"
                    name="fromPhone"
                    type="text"
                    className="form-control"
                    required
                    pattern="[0-9]+"
                    defaultValue={
                      editOrAddModal ? shipmentRef.current.fromPhone : ""
                    }
                  />
                </FormGroup>
              </div>
              <div className="custom-sender-details">
                <FormGroup>
                  <Label htmlFor="to">Receiver Address</Label>
                  <Input
                    id="to"
                    name="to"
                    type="textarea"
                    className="form-control"
                    required
                    defaultValue={editOrAddModal ? shipmentRef.current.to : ""}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="toPhone">Receiver Phone No.</Label>
                  <Input
                    id="toPhone"
                    name="toPhone"
                    type="text"
                    className="form-control"
                    required
                    pattern="[0-9]+"
                    defaultValue={
                      editOrAddModal ? shipmentRef.current.toPhone : ""
                    }
                  />
                </FormGroup>
              </div>
            </div>
            <div className="custom-form-grouping">
              <h4 className="custom-form-grouping-header">Shipment details</h4>
              <FormGroup>
                <Label htmlFor="origin">Origin</Label>

                <select
                  className="form-control"
                  id="origin"
                  name="origin"
                  required
                  defaultValue={
                    editOrAddModal ? shipmentRef.current.origin : ""
                  }
                >
                  <option>Select</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Åland Islands">Åland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic of The">
                    Congo, The Democratic Republic of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </option>
                  <option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="Korea, Republic of">Korea, Republic of</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao">Macao</option>
                  <option value="Macedonia, The Former Yugoslav Republic of">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </option>
                  <option value="Moldova, Republic of">
                    Moldova, Republic of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </option>
                  <option value="Saint Vincent and The Grenadines">
                    Saint Vincent and The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and The South Sandwich Islands">
                    South Georgia and The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-leste">Timor-leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.S.">
                    Virgin Islands, U.S.
                  </option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="origin">Destination</Label>
                <select
                  className="form-control"
                  id="destination"
                  name="destination"
                  required
                  defaultValue={
                    editOrAddModal ? shipmentRef.current.destination : ""
                  }
                >
                  <option>Select</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Åland Islands">Åland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic of The">
                    Congo, The Democratic Republic of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </option>
                  <option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="Korea, Republic of">Korea, Republic of</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao">Macao</option>
                  <option value="Macedonia, The Former Yugoslav Republic of">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </option>
                  <option value="Moldova, Republic of">
                    Moldova, Republic of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </option>
                  <option value="Saint Vincent and The Grenadines">
                    Saint Vincent and The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and The South Sandwich Islands">
                    South Georgia and The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-leste">Timor-leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.S.">
                    Virgin Islands, U.S.
                  </option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="modeofShipment">Mode of Shipment</Label>
                <select
                  className="form-control"
                  name="shipmentMode"
                  id="shipmentMode"
                  required
                  defaultValue={
                    editOrAddModal ? shipmentRef.current.shipmentMode : ""
                  }
                >
                  <option>Select</option>
                  <option value={0}>Air Freight</option>
                  <option value={1}>Road Freight</option>
                  <option value={2}>Sea Freight</option>
                  <option value={3}>Rail Freight</option>
                </select>
              </FormGroup>

              <FormGroup>
                <Label htmlFor="shipmentType">Type of Shipment</Label>
                <select
                  className="form-control"
                  name="shipmentType"
                  id="shipmentType"
                  required
                  defaultValue={
                    editOrAddModal ? shipmentRef.current.shipmentType : ""
                  }
                >
                  <option>Select</option>
                  <option value={0}>
                    Not perishable and not dangerous products
                  </option>
                  <option value={1}>Hazardous</option>
                  <option value={2}>Perishable</option>
                  <option value={3}>Others</option>
                </select>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="receivedAt">Date of Shipment Received</Label>
                <Input
                  id="receivedAt"
                  name="receivedAt"
                  type="date"
                  className="form-control"
                  required
                  max={maxDate}
                  defaultValue={
                    editOrAddModal
                      ? moment(shipmentRef.current.receivedAt).format(
                          "YYYY-MM-DD"
                        )
                      : ""
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="productAndQuantity">Products & Quantity</Label>
                <Input
                  id="productAndQuantity"
                  name="productAndQuantity"
                  type="textarea"
                  className="form-control"
                  required
                  defaultValue={
                    editOrAddModal ? shipmentRef.current.productAndQuantity : ""
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="weight">Weight</Label>
                <Input
                  id="weight"
                  name="weight"
                  type="text"
                  className="form-control"
                  required
                  pattern="[0-9]+"
                  defaultValue={
                    editOrAddModal ? shipmentRef.current.weight : ""
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="no">No. of Packages</Label>
                <Input
                  id="noOfPackages"
                  name="noOfPackages"
                  type="text"
                  className="form-control"
                  required
                  pattern="[0-9]+"
                  defaultValue={
                    editOrAddModal ? shipmentRef.current.noOfPackages : ""
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label htmlFor="comments">Comments</Label>
                <Input
                  id="comments"
                  name="comments"
                  type="textarea"
                  className="form-control"
                  required
                  defaultValue={
                    editOrAddModal ? shipmentRef.current.comments : ""
                  }
                />
              </FormGroup>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary">
                {editOrAddModal ? "Edit Shipment" : "Add Shipment"}
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default ProductPage;
