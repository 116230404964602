import React from 'react'
import statusOptions from "../../data/statusOptions";
import "./statusStyles.css";
const StatusDisplay = ({ value }) => {
  let statusInstance = statusOptions.filter(
    (status) => status.value == value
  )[0];
  return (
    <span className={`${statusInstance.className}`}>
      {statusInstance.label}
    </span>
  );
};
export default StatusDisplay