import axios from 'axios'

const url = '/api/'

export default (endpoint, params) => {
    const options = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    }
    return axios.post(url + endpoint, params, options)
}