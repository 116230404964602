const typeOptions = [
  {
    label: "Not perishable and not dangerous products",
    value: 0,
  },
  { label: "Hazardous", value: 1 },
  { label: "Perishable", value: 2 },
  { label: "General goods", value: 4 },
  { label: "Personal effects", value: 5 },
  { label: "Others", value: 3 },
];
export default typeOptions;
