import React from "react";

import BaseSelect from "react-select";
import FixRequiredSelect from "../../funs/FixRequiredSelect";
const Select = props => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      
    />
  );

  export default Select;