import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import toastr from "toastr";
import Select from "react-select";
import { CSVLink, CSVDownload } from "react-csv";
import "toastr/build/toastr.min.css";
import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import cookie from "js-cookie";
import decode from "jwt-decode";

import authReq from "funs/authReq";

let token = cookie.get("token") || "";
let userData = token ? decode(token) : {};
function ProductPage() {
  const [csvData, setCsvData] = useState([]);
  const [modal, setModal] = useState(false);
  const [editOrAddModal, setEditOrAddModal] = useState(false); //false-add, true-edit

  const [image, setImage] = useState(null);

  const reseller = useRef("");
  const productRef = useRef("");

  let history= useHistory();
  const toggle = (id, status) => {
    authReq("enable-server", { id, status }).then((res) => {
      // console.log(res.data)
      // toastr.success('Added successfuly','Success')
      // setModal(false)
      // getData()
    });
    // enableProduct({
    // 	variables: {
    // 		id, status
    // 	}
    // })
  };
  const getData = () => {
    authReq("get-groups").then(({ data }) => {
      // console.log(data);
      if(data.success==true){
        let n = [["Voucher Code", "Validity Days"]];
        let dt = data.allGroups.map((item,index) => {
          item.index=index+1;
          item.actions = (
            <>
              <button
                className="editButton" 
                onClick={() => {
                 
                  history.push(`/edit-group/${item.referenceId}`)
                }}
              >
                Edit
              </button>
              <button
              className="deleteButtonGroup"  style={{background:"#F14646"}}
              onClick={() => {
                authReq("delete-group", {referenceId:item.referenceId}).then((res) => {
                  // console.log(res.data)
                  if(res.data.success==true){
                    toastr.success("Deleted successfuly", "Success");
                    getData();
                  }
  
                });
              }}
            >
              Delete
            </button>
            </>
          );
          item.trackingIds=item.trackingIds.join(", ")
          return item;
        });
        setCsvData(n);
        setData((prev) => {
          return { ...prev, rows: dt };
        });
      }
  
    });
  };
  const [resellers, setResellers] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const submit = (e) => {
    e.preventDefault();
    // console.log("clic");
    let formData = new FormData();
    
    document.modalbody.key.value &&
      formData.append("key", document.modalbody.key.value);
    document.modalbody.value.value &&
      formData.append("value", document.modalbody.value.value);
    if (editOrAddModal) {
      formData.append("_id", productRef.current._id);
    }

    authReq(editOrAddModal ? "edit-config" : "add-config", formData).then(
      (res) => {
        // console.log(res.data)
        toastr.success("Added successfuly", "Success");
        setModal(false);
        getData();
      }
    );
  };
  const tog_modal = () => {
    setModal((prev) => !prev);
  };
  const [data, setData] = useState({
    columns: [
    
      {
        label: "Reference Id",
        field: "referenceId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });
  const handleImg = (e) => setImage(e.target.files[0]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Management" breadcrumbItem="List Group" />
          {userData.role > 1 && (
            <Row className="mb-2">
              <Col sm="12">
                <div className="text-sm-right">
                  <Button
                    onClick={() => {
                      // tog_modal();
                      // setEditOrAddModal(false);
                      history.push("/add-group")
                    }}
                    type="button"
                    color="success"
                    className="btn-rounded waves-effect waves-light mb-2 mr-2"
                  >
                    <i className="mdi mdi-plus mr-1"></i> Add Group
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>Stripped example </CardTitle>
                    <CardSubtitle className="mb-3">
                      mdbreact DataTables has most features enabled by default, so
                      all you need to do to use it with your own tables is to call
                    the construction function:{" "}
                      <code>&lt;MDBDataTable striped /&gt;</code>.
                  </CardSubtitle> */}

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <CSVLink
            filename={"vouchers.csv"}
            class="btn btn-primary mb-2"
            data={csvData}
          >
            Download CSV
          </CSVLink> */}
        </div>
      </div>
      <Modal
        size="md"
        isOpen={modal}
        toggle={tog_modal}
        scrollable={true}
        onClosed={() => {
          productRef.current = {};
        }}
        // centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {editOrAddModal ? "Edit Setting" : "Add Setting"}
          </h5>
          <button
            type="button"
            onClick={tog_modal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form name="modalbody" onSubmit={submit}>
            {/* new fields  */}
            <FormGroup>
              <Label htmlFor="key">Key</Label>
              <Input
                id="key"
                name="key"
                type="text"
                className="form-control"
                defaultValue={productRef.current.key}
                disabled={editOrAddModal ? true: false}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="value">Value</Label>
              <Input
                id="value"
                name="value"
                type="text"
                className="form-control"
                defaultValue={productRef.current.value}
              />
            </FormGroup>

          
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setImage(null);
                  tog_modal();
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  setImage(null);
                }}
              >
                Save changes
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default ProductPage;
