import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import cookie from "js-cookie";
import decode from "jwt-decode";

//i18n
import { withNamespaces } from "react-i18next";

let token = cookie.get("token") || "";
let userData = token ? decode(token) : {};
class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if ("/admin" + this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {userData.role > 1 && (
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span>{this.props.t("Dashboard")}</span>
                </Link>
              </li>
            )}

            {userData.role > 1 && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-ship"></i>
                  <span>Shipment</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/shipments">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>All Shipments</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/add-shipment">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>Add Shipment</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/add-bulk-shipments">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>Add Bulk Shipments</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/update-status-shipment">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>Update Status</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
                  {userData.role > 1 && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                <i className='bx bxs-collection' ></i>
                  <span>Group</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/add-group">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>Add Group</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/update-status-group">
                      <i className="bx bx-radio-circle-marked"></i>
                      <span>Update Status</span>
                    </Link>
                  </li>
                 
                </ul>
              </li>
            )}
            {userData.role > 1 && (
              <li>
                <Link to="/configuration" className="waves-effect">
                <i className='bx bxs-wrench' ></i>
                  <span>Configuration</span>
                </Link>
              </li>
            )}
            {/* {userData.role > 1 && (
              <li>
                <Link to="/shipments" className="waves-effect">
                  <i className="bx bxs-ship"></i>
                  <span>Shipments</span>
                </Link>
              </li>
            )} */}
            {/* {userData.role > 1 && (
              <li>
                <Link to="/add-shipment" className="waves-effect">
                  <i className="bx bxs-ship"></i>
                  <span>Add Shipment</span>
                </Link>
              </li>
            )}
            {userData.role > 1 && (
              <li>
                <Link to="/update-status-shipment" className="waves-effect">
                  <i className="bx bx-barcode"></i>
                  <span>Update shipment status </span>
                </Link>
              </li>
            )} */}

            {/* {userData.role > 1 && (
              <li>
                <Link to="/add-group" className="waves-effect">
                  <i className="bx bxs-book-open"></i>
                  <span>Add Group</span>
                </Link>
              </li>
            )} */}

            {/* {userData.role > 1 && (
              <li>
                <Link to="/list-group" className="waves-effect">
                <i className="bx bxs-book-open"></i>
                  <span>List group</span>
                </Link>
              </li>
            )} */}
            {/* {userData.role > 1 && (
              <li>
                <Link to="/update-status-group" className="waves-effect">
                  <i className="bx bxs-book-open"></i>
                  <span>Update group status</span>
                </Link>
              </li>
            )} */}
            
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(SidebarContent));
