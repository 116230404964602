import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
  Input,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import toastr from "toastr";
import { CSVLink, CSVDownload } from "react-csv";
import "toastr/build/toastr.min.css";
import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Import StatusDisplay
import StatusDisplay from "../../components/StatusDisplay/StatusDisplay";
import countryNames from "../../data/countryNames";
import modeOptions from "../../data/modeOptions";
import typeOptions from "../../data/typeOptions";
import statusOptions from "../../data/statusOptions";

import Select from "../../components/CustomSelect/CustomSelect";

import "./datatables.scss";
import cookie from "js-cookie";
import decode from "jwt-decode";

import authReq from "funs/authReq";
import { ToastHeader } from "reactstrap";
// import { flexibleCompare } from "@fullcalendar/core/util/misc";

let token = cookie.get("token") || "";
let userData = token ? decode(token) : {};
function ProductPage() {
  const [referenceIdState, setReferenceIdState] = useState();

  const [maxDate, setMaxDate] = useState(null);

  const [statusHistory, setStatusHistory] = useState({
    columns: [
      {
        label: "Date",
        field: "markedAt",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 120,
      },

      {
        label: "Remarks",
        field: "remarks",
        sort: "asc",
        width: 120,
      },
    ],
    rows: [],
  });

  const [newStatusDate, setNewStatusDate] = useState();
  const [newStatus, setNewStatus] = useState();
  const [newStatusRemarks, setNewStatusRemarks] = useState();

  const [trackingIdState, setTrackingIdState] = useState();
  const [selectedTypeOption, setSelectedTypeOption] = useState();
  const [selectedModeOption, setSelectedModeOption] = useState();

  const [selectedOriginOption, setSelectedOriginOption] = useState();
  const [selectedDestinationOption, setSelectedDestinationOption] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [showStatusLog, setShowStatusLog] = useState(false);

  const { trackingId } = useParams();
  const history = useHistory();
  useEffect(() => {
    setMaxDate(moment().format("YYYY-MM-DD"));
  }, []);

  //poplulate feed function
  const populateFeilds = (trackingId) => {
    authReq("get-shipment", { trackingId }).then(({ data }) => {
      if (data.success == false) {
        // history.push("/shipments");

        setShowDetails(false);

        // toastr.error("Cannot fetch shipment data", "Error");
      } else if (data.success == true) {
        setShowDetails(true);

        //populate it in the fields
        let {
          trackingId,
          from,
          fromPhone,
          to,
          toPhone,
          productAndQuantity,
          weight,
          shipmentMode,
          shipmentType,
          noOfPackages,
          origin,
          destination,
          receivedAt,
          comments,
        } = data.data;

        document.shipment.trackingId.value = trackingId;
        document.shipment.from.value = from;
        document.shipment.fromPhone.value = fromPhone;
        document.shipment.to.value = to;
        document.shipment.toPhone.value = toPhone;
        document.shipment.productAndQuantity.value = productAndQuantity;
        document.shipment.weight.value = weight;
        document.shipment.noOfPackages.value = noOfPackages;
        document.shipment.shipmentMode.value = shipmentMode;
        document.shipment.shipmentType.value = shipmentType;
        document.shipment.origin.value = origin;
        document.shipment.destination.value = destination;
        document.shipment.receivedAt.value =
          moment(receivedAt).format("YYYY-MM-DD");
        document.shipment.comments.value = comments;

        setTrackingIdState(trackingId);
        setSelectedTypeOption(
          typeOptions.filter((x) => x.value == shipmentType)[0].label
        );
        setSelectedModeOption(
          modeOptions.filter((x) => x.value == shipmentMode)[0].label
        );
        // console.log(countryNames.filter((x) => x.value == origin)[0]);
        setSelectedOriginOption(
          countryNames.filter((x) => x.value == origin)[0].label
        );
        setSelectedDestinationOption(
          countryNames.filter((x) => x.value == destination)[0].label
        );
      }
    });
    authReq("get-status-log", { trackingId }).then(({ data }) => {
      if (data.success == false) {
        setShowStatusLog(false);
        toastr.error("Cannot fetch data","Error")
      } else if (data.success == true) {
        // alert("here")
        setShowStatusLog(true);

        let dts = data.statusLog.map((item, index) => {
          item.status = <StatusDisplay value={item.status} />;

          item.markedAt = new Date(item.markedAt).toLocaleDateString();

          return item;
        });
        setStatusHistory((prev) => {
          // console.log("state");
          return { ...prev, rows: dts };
        });
      }
    });
  };
  useEffect(() => {
    if (trackingId != null) {
      populateFeilds(trackingId);
    }
  }, []);
  const getTrackingDetails = (e) => {
    e.preventDefault();
    populateFeilds(trackingIdState);
  };
  const updateStatusSubmit = (e) => {
    e.preventDefault();
    // updationMode,status,remarks,markedAt

    // return
    let dataToSend = {
      trackingId: trackingIdState,
      updationMode: 0,
      markedAt: newStatusDate,
      status: newStatus.value,
      remarks: newStatusRemarks,
    };
    authReq("update-shipments", dataToSend).then(({ data }) => {
      if (data.success == true) {
        toastr.success("Updated successfuly", "Success");
        populateFeilds(trackingIdState);
      }
      else if(data.success == false)
      {
        toastr.error("Not updated ", "Error");
      }
    });
  };

  const editShipmentSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Management"
            breadcrumbItem="Update status shipment "
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <form onSubmit={getTrackingDetails}>
                    <h6 htmlFor="referenceId">Enter Tracking Id</h6>
                    <input
                      type="text"
                      value={trackingIdState}
                      id="referenceId"
                      name="referenceId"
                      placeholder="tracking id"
                      required
                      className="createGroupTextbox"
                      onChange={(e) => {
                        setTrackingIdState(e.target.value);
                      }}
                    />
                    <button
                      type="submit"
                      id="createGp"
                      className="createGroupButton float-md-right"
                    >
                      Get details
                    </button>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {showDetails && (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Form name="shipment" onSubmit={editShipmentSubmit}>
                      <div className="custom-form-tracking-grouping">
                        <h4 className="custom-form-grouping-header">
                          Tracking Details
                        </h4>
                        <div className="row">
                          <FormGroup className="col-md-6">
                            <Label htmlFor="trackingId">Tracking Id</Label>
                            <Input
                              id="trackingId"
                              name="trackingId"
                              type="text"
                              className="form-control"
                              disabled={true}
                              required
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="custom-form-send-receiver-grouping">
                        <h4 className="custom-form-grouping-header">
                          Sender / Receiver details
                        </h4>
                        <div className="row">
                          {" "}
                          <div className="col-md-6">
                            <FormGroup>
                              <Label htmlFor="from">Sender Address</Label>
                              <Input
                                id="from"
                                name="from"
                                type="textarea"
                                className="form-control"
                                required
                                disabled={true}
                              />
                            </FormGroup>

                            <FormGroup>
                              <Label htmlFor="fromPhone">
                                Sender Phone No.
                              </Label>
                              <Input
                                id="fromPhone"
                                name="fromPhone"
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label htmlFor="to">Receiver Address</Label>
                              <Input
                                id="to"
                                name="to"
                                type="textarea"
                                className="form-control"
                                required
                                disabled={true}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="toPhone">
                                Receiver Phone No.
                              </Label>
                              <Input
                                id="toPhone"
                                name="toPhone"
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>

                      <div className="custom-form-grouping">
                        <h4 className="custom-form-grouping-header">
                          Shipment details
                        </h4>
                        {/* <div className="custom-form-section1 "> */}
                        <div className="row">
                          <FormGroup className="col-md-6">
                            <Label htmlFor="productAndQuantity">
                              Products & Quantity
                            </Label>
                            <Input
                              id="productAndQuantity"
                              name="productAndQuantity"
                              type="textarea"
                              className="form-control"
                              required
                              disabled={true}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-3">
                            <Label htmlFor="weight">Weight</Label>
                            <Input
                              id="weight"
                              name="weight"
                              type="text"
                              className="form-control"
                              required
                              disabled={true}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-3">
                            <Label htmlFor="no">No. of Packages</Label>
                            <Input
                              id="noOfPackages"
                              name="noOfPackages"
                              type="text"
                              className="form-control"
                              required
                              pattern="[0-9]+"
                              disabled={true}
                            />
                          </FormGroup>
                        </div>

                        <div className="row">
                          <FormGroup className="col-md-3">
                            <Label htmlFor="modeofShipment">
                              Mode of Shipment
                            </Label>

                            <Input
                              name="shipmentMode"
                              id="shipmentMode"
                              value={selectedModeOption}
                              onChange={setSelectedModeOption}
                              type="text"
                              className="form-control"
                              required
                              disabled={true}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-3">
                            <Label htmlFor="shipmentType">
                              Type of Shipment
                            </Label>

                            <Input
                              value={selectedTypeOption}
                              onChange={setSelectedTypeOption}
                              name="shipmentType"
                              id="shipmentType"
                              className="form-control"
                              required
                              disabled={true}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-3">
                            <Label htmlFor="origin">Origin</Label>

                            <Input
                              value={selectedOriginOption}
                              onChange={setSelectedOriginOption}
                              name="origin"
                              id="origin"
                              required
                              className="form-control"
                              disabled={true}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-3">
                            <Label htmlFor="origin">Destination</Label>
                            <Input
                              value={selectedDestinationOption}
                              onChange={setSelectedDestinationOption}
                              name="destination"
                              id="destination"
                              required
                              disabled={true}
                              className="form-control"
                            />
                          </FormGroup>
                        </div>
                        <div className="row">
                          <FormGroup className="col-md-3">
                            <Label htmlFor="receivedAt">
                              Date of Shipment Received
                            </Label>
                            <Input
                              id="receivedAt"
                              name="receivedAt"
                              type="date"
                              className="form-control"
                              required
                              max={maxDate}
                              disabled={true}
                            />
                          </FormGroup>
                          <FormGroup className="col-md-9">
                            <Label htmlFor="comments">Comments</Label>
                            <Input
                              id="comments"
                              name="comments"
                              type="textarea"
                              className="form-control"
                              required
                              rows="1"
                              disabled={true}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {showStatusLog && (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <h4 className="custom-form-grouping-header">
                      Status history
                    </h4>
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      data={statusHistory}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {showDetails && showStatusLog && (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <h4 className="custom-form-grouping-header">
                      Update shipment status
                    </h4>
                    <form onSubmit={updateStatusSubmit}>
                      <div className="row">
                        <FormGroup className="col-md-4">
                          <Label htmlFor="receivedAt">Date</Label>
                          <Input
                            type="date"
                            className="form-control"
                            required
                            max={maxDate}
                            value={newStatusDate}
                            onChange={(e) => {
                              setNewStatusDate(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                          <Label htmlFor="modeofShipment">Current Status</Label>
                          <Select
                            name="shipmentMode"
                            id="shipmentMode"
                            required
                            options={statusOptions}
                            value={newStatus}
                            onChange={setNewStatus}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-4">
                          <Label htmlFor="comments">Current Remarks</Label>
                          <Input
                            id="comments"
                            name="comments"
                            type="textarea"
                            className="form-control"
                            required
                            rows="1"
                            value={newStatusRemarks}
                            onChange={(e) => {
                              setNewStatusRemarks(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            width: "100%",
                          }}
                        >
                          <button type="submit" className="btn btn-primary">
                            Update status
                          </button>
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductPage;
