import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import {
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
//icons
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import toastr from "toastr";
import { CSVLink, CSVDownload } from "react-csv";
import "toastr/build/toastr.min.css";
import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import countryNames from "../../data/countryNames";
import statusOptions from "../../data/statusOptions";

import Select from "../../components/CustomSelect/CustomSelect";

import "./datatables.scss";
import cookie from "js-cookie";
import decode from "jwt-decode";

import authReq from "funs/authReq";
import StatusDisplay from "components/StatusDisplay/StatusDisplay";
import { ToastHeader } from "reactstrap";
// import { flexibleCompare } from "@fullcalendar/core/util/misc";

let token = cookie.get("token") || "";
let userData = token ? decode(token) : {};
function ProductPage() {
  const [referenceIdState, setReferenceIdState] = useState(null);
  const [deleteReferenceId, setDeleteReferenceId] = useState();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const toggleConfirmDialog = () => {
    setConfirmDialog((prev) => {
      prev = !prev;
    });
  };
  const [maxDate, setMaxDate] = useState(null);
  const [groupData, setGroupData] = useState({
    columns: [
      {
        label: "Group Id",
        field: "referenceId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Created At",
        field: "createdAt",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });
  const [data, setData] = useState({
    columns: [
      {
        label: "Tracking Id",
        field: "trackingId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Sender",
        field: "from",
        sort: "asc",
        width: 120,
      },

      {
        label: "Receiver",
        field: "to",
        sort: "asc",
        width: 120,
      },
      {
        label: "Origin",
        field: "origin",
        sort: "asc",
        width: 120,
      },

      {
        label: "Destination",
        field: "destination",
        sort: "asc",
        width: 120,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 120,
      },
    ],
    rows: [],
  });

  const [newStatusDate, setNewStatusDate] = useState();
  const [newStatus, setNewStatus] = useState();
  const [newStatusRemarks, setNewStatusRemarks] = useState();
  const [showShipmentNdUpdate, setShowShipmentNdUpdate] = useState(false);
  const { referenceId } = useParams();
  const history = useHistory();
  useEffect(() => {
    setMaxDate(moment().format("YYYY-MM-DD"));
    getData();
  }, []);
  useEffect(() => {
    if (referenceId != null) {
      setReferenceIdState(referenceId);
      authReq("get-group-shipments", { referenceId }).then(({ data }) => {
        if (data.success == true) {
          // console.log(data.shipmentsPresent);
          setShowShipmentNdUpdate(true);
          scrollTo();
          let dts = data.shipmentsPresent.map((item, index) => {
            item.status = <StatusDisplay value={item.status} />;

            item.createdAt = new Date(item.createdAt).toLocaleDateString();
            item.receivedAt = new Date(item.receivedAt).toLocaleDateString();
            item.origin = countryNames.filter(
              (x) => x.value == item.origin
            )[0].label;
            item.destination = countryNames.filter(
              (x) => x.value == item.destination
            )[0].label;
            return item;
          });
          setData((prev) => {
            // console.log("state");
            return { ...prev, rows: dts };
          });
        } else if (data.success == false) {
          toastr.error("Cannot fetch data", "Error");
        }
      });
    }
  }, []);

  const getData = () => {
    authReq("get-groups").then(({ data }) => {
      // console.log(data);
      if (data.success == true) {
        let dt = data.allGroups.map((item, index) => {
          item.index = index + 1;
          item.createdAt=moment(item.createdAt).format("DD-MM-YY")
          item.actions = (
            <div className="row justify-content-left">
              <Link
                id={"editButton" + index}
                to={`/edit-group/${item.referenceId}`}
                className="text-center"
                style={{
                  cursor: "pointer",
                  background: "#2ca579",
                  color: "white",
                  borderRadius: "50%",
                  margin: "5px",
                  padding: "2px",
                }}
              >
                <BiEditAlt
                  style={{ width: "24px", padding: "5px" }}
                  size="1x"
                />
              </Link>
              <UncontrolledTooltip
                placement="bottom"
                target={"editButton" + index}
              >
                Edit
              </UncontrolledTooltip>

              <span
                id={"updateButton" + index}
                className="text-center"
                style={{
                  cursor: "pointer",
                  background: "#7587e0",
                  color: "white",
                  borderRadius: "50%",
                  margin: "5px",
                  padding: "2px",
                }}
                onClick={() => handleSearchShowGroupDetails(item.referenceId)}
              >
                <MdOutlinePublishedWithChanges
                  style={{ width: "24px", padding: "5px" }}
                  size="1x"
                />
              </span>
              <UncontrolledTooltip
                placement="bottom"
                target={"updateButton" + index}
              >
                Update
              </UncontrolledTooltip>
              <span
                id={"deleteButton" + index}
                className=""
                style={{
                  cursor: "pointer",
                  background: "#F14646",
                  color: "white",
                  borderRadius: "50%",
                  margin: "5px",
                  padding: "2px",
                }}
                onClick={() => {
                  setConfirmDialog(true);
                  setDeleteReferenceId(item.referenceId);
                }}
              >
                <RiDeleteBin6Line
                  style={{ width: "24px", padding: "5px" }}
                  size="1x"
                />
              </span>
              <UncontrolledTooltip
                placement="bottom"
                target={"deleteButton" + index}
              >
                Delete
              </UncontrolledTooltip>
            </div>
          );
          item.trackingIds = item.trackingIds.join(", ");
          return item;
        });
        setGroupData((prev) => {
          return { ...prev, rows: dt };
        });
      }
    });
  };

  //use this when update status button is present
  const handleSearchShowGroupDetails = (referenceId) => {
    setReferenceIdState(referenceId);
    authReq("get-group-shipments", { referenceId }).then(({ data }) => {
      if (data.success == true) {
        setShowShipmentNdUpdate(true);
        scrollTo();
        // console.log(data.shipmentsPresent);
        let dts = data.shipmentsPresent.map((item, index) => {
          item.status = <StatusDisplay value={item.status} />;

          item.createdAt = new Date(item.createdAt).toLocaleDateString();
          item.receivedAt = new Date(item.receivedAt).toLocaleDateString();
          item.origin = countryNames.filter(
            (x) => x.value == item.origin
          )[0].label;
          item.destination = countryNames.filter(
            (x) => x.value == item.destination
          )[0].label;
          return item;
        });
        setData((prev) => {
          // console.log("state");
          return { ...prev, rows: dts };
        });
      }
    });
  };
  const getRefeerenceDetails = (e) => {
    e.preventDefault();

    authReq("get-group-shipments", { referenceId: referenceIdState }).then(
      ({ data }) => {
        if (data.success == true) {
          setShowShipmentNdUpdate(true);
          scrollTo();
          // console.log(data.shipmentsPresent);
          let dts = data.shipmentsPresent.map((item, index) => {
            item.status = <StatusDisplay value={item.status} />;

            item.createdAt = new Date(item.createdAt).toLocaleDateString();
            item.receivedAt = new Date(item.receivedAt).toLocaleDateString();
            item.origin = countryNames.filter(
              (x) => x.value == item.origin
            )[0].label;
            item.destination = countryNames.filter(
              (x) => x.value == item.destination
            )[0].label;
            return item;
          });
          setData((prev) => {
            // console.log("state");
            return { ...prev, rows: dts };
          });
        } else if (data.success == false) {
          toastr.error("Cannot fetch data", "Error");
        }
      }
    );
  };
  const updateStatusSubmit = (e) => {
    e.preventDefault();

    let dataToSend = {
      referenceId: referenceIdState,
      updationMode: 1,
      markedAt: newStatusDate,
      status: newStatus.value,
      remarks: newStatusRemarks,
    };
    authReq("update-shipments", dataToSend).then(({ data }) => {
      if (data.success == true) {
        toastr.success("Updated successfuly", "Success");
        //used for reloading
        handleSearchShowGroupDetails(referenceIdState);
      } else if (data.success == false) {
        toastr.error("Not updated ", "Error");
      }
    });
  };

  const scrollTo = () => {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        {/* delete confirmation */}
        <Modal isOpen={confirmDialog} toggle={toggleConfirmDialog}>
          <ModalHeader toggle={toggleConfirmDialog}>Confirmation</ModalHeader>
          <ModalBody>Are you sure you want to delete the group?</ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                setConfirmDialog(false);
                authReq("delete-group", {
                  referenceId: deleteReferenceId,
                }).then((res) => {
                  // console.log(res.data)
                  if (res.data.success == true) {
                    toastr.success("Deleted successfuly", "Success");
                    getData();
                  }
                });
              }}
            >
              Yes Delete
            </Button>{" "}
            <Button color="secondary" onClick={toggleConfirmDialog}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div className="container-fluid">
          <Breadcrumbs
            title="Management"
            breadcrumbItem="Update status group "
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <form onSubmit={getRefeerenceDetails}>
                    <h6 htmlFor="referenceId">Enter Group Id</h6>
                    <input
                      type="text"
                      value={referenceIdState}
                      id="referenceId"
                      name="referenceId"
                      placeholder="group id"
                      required
                      className="createGroupTextbox"
                      onChange={(e) => {
                        setReferenceIdState(e.target.value);
                      }}
                    />
                    <button
                      type="submit"
                      id="createGp"
                      className="createGroupButton float-md-right"
                    >
                      Get details
                    </button>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h4 className="custom-form-grouping-header">All groups</h4>
                  <MDBDataTable responsive striped bordered data={groupData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {showShipmentNdUpdate && (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    {/* <CardTitle>Stripped example </CardTitle>
                   <CardSubtitle className="mb-3">
                      mdbreact DataTables has most features enabled by default, so
                      all you need to do to use it with your own tables is to call
                    the construction function:{" "}
                      <code>&lt;MDBDataTable striped /&gt;</code>.
                  </CardSubtitle> */}
                    <h4
                      name="scroll-to-element"
                      className="custom-form-grouping-header"
                    >
                      Shipments in the group
                    </h4>
                    <MDBDataTable responsive striped bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {showShipmentNdUpdate && (
            <Row>
              <Col className="">
                <Card>
                  <CardBody>
                    <h4 className="custom-form-grouping-header">
                      Update group status
                    </h4>
                    <form onSubmit={updateStatusSubmit}>
                      <div className="row">
                        <FormGroup className="col-md-4">
                          <Label htmlFor="receivedAt">Date</Label>
                          <Input
                            type="date"
                            className="form-control"
                            required
                            max={maxDate}
                            value={newStatusDate}
                            onChange={(e) => {
                              setNewStatusDate(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="col-md-4">
                          <Label htmlFor="modeofShipment">Current Status</Label>
                          <Select
                            name="shipmentMode"
                            id="shipmentMode"
                            required
                            options={statusOptions}
                            value={newStatus}
                            onChange={setNewStatus}
                          />
                        </FormGroup>

                        <FormGroup className="col-md-4">
                          <Label htmlFor="comments">Current Remarks</Label>
                          <Input
                            id="comments"
                            name="comments"
                            type="textarea"
                            className="form-control"
                            required
                            rows="1"
                            value={newStatusRemarks}
                            onChange={(e) => {
                              setNewStatusRemarks(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            width: "100%",
                          }}
                        >
                          <button type="submit" className="btn btn-primary">
                            Update status
                          </button>
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductPage;
