import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import authReq from "funs/authReq";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withNamespaces } from "react-i18next";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.fetchCount.bind(this);
    this.togglemodal.bind(this);
  }

  componentDidMount() {
    this.fetchCount();
    // this.timer = setInterval(() => this.fetchCount(), 5000);
  }
  fetchCount = () => {
    authReq("get-count-dashboard").then(({ data }) => {
      let {
        status1,
        status2,
        status3,
        status4,
        status5,
        status6,
        status7,
        status8,
      } = data.data;
      this.setState({
        ...this.state,
        reports: [
          {
            title: "Total Shipments",
            iconClass: "bxs-ship",
            description:
              status1 +
              status2 +
              status3 +
              status4 +
              status5 +
              status6 +
              status7 +
              status8,
          },
          {
            title: " Shipments in Process",
            iconClass: " bx-terminal",
            description: status2,
          },
          {
            title: "  Shipments Forwarded",
            iconClass: "bx-fast-forward",
            description: status3,
          },
          {
            title: " Shipments in Transit",
            iconClass: "bxs-cart",
            description: status4,
          },
          {
            title: " Shipments Under Customs Clearance",
            iconClass: "bxs-badge-check",
            description: status5,
          },
          {
            title: " Shipments Out for Delivery",
            iconClass: "bxs-box",
            description: status6,
          },
          {
            title: " Shipments Delivered",
            iconClass: "bxs-package",
            description: status7,
          },
          {
            title: " Shipments Canceled",
            iconClass: "bxs-x-square",
            description: status8,
          },
        ],
      });

      console.log(this.state);
    });
  };

  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Dashboard")}
              breadcrumbItem={this.props.t("Dashboard")}
            />

            <Row>
              <Col xl="12">
                <Row>
                  {/* Reports Render */}
                  {this.state?.reports?.map((report, key) => (
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted font-weight-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </Media>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Dashboard);
