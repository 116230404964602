import React, { Component, useState } from 'react';

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError } from '../../store/actions';

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logoTracker.png";

import authReq from 'funs/authReq'

function Login({ history }) {
    const [logg, setLogg] = useState(false)
    const onSubmit = e => {
        e.preventDefault()
        let data = {
            username: document.loginform.username.value,
            password: document.loginform.password.value
        }
        authReq('login', data).then(({ data }) => {
            if(data.status) {
                window.location = 'dashboard'
            }
        })
    }

    return (
        <React.Fragment>
            {logg && <Redirect to="/dashboard" push={true} />}
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark"><i className="fas fa-home h2"></i></Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-soft-primary">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Back !</h5>
                                                <p>Sign in to continue</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-square bg-dark">
                                                    <img src={logo} alt="" className="rounded-square" height="74" />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">

                                        <AvForm name="loginform" className="form-horizontal" onValidSubmit={onSubmit}>

                                            {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null} */}

                                            <div className="form-group">
                                                <AvField name="username" label="Username" value="" className="form-control" placeholder="Enter username" type="text" required />
                                            </div>

                                            <div className="form-group">
                                                <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" />
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                            </div>

                                            <div className="mt-3">
                                                <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Log In</button>
                                            </div>

                                            {/* <div className="mt-4 text-center">
                                                <Link to="#" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                            </div> */}
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser, apiError })(Login));

