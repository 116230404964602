const statusOptions = [

  { label: "Shipment Booked", value: 1,className:"statusBooked" },
  { label: "Shipment in Process", value: 2,className: "statusProcess"},
  { label: "Shipment Forwarded", value: 3,className:"statusForwarded" },
  { label: "Shipment in Transit", value: 4,className: "statusTransit"},
  { label: "Shipment under Customs Clearance", value: 5,className:"statusCustomClearance" },
  { label: "Shipment Out for Delivery", value: 6,className: "statusOutForDelivery"},
  { label: "Shipment Delivered", value: 7,className: "statusDelivered"},
  { label: "Shipment Canceled", value: 8,className: "statusCanceled"},


];
export default statusOptions;
