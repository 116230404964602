import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import toastr from "toastr";
import countryNames from "../../data/countryNames";
import "toastr/build/toastr.min.css";
import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import cookie from "js-cookie";
import decode from "jwt-decode";
import Select from "../../components/CustomSelect/CustomSelect";
import typeOptions from "../../data/typeOptions";
import modeOptions from "../../data/modeOptions";

import authReq from "funs/authReq";
let token = cookie.get("token") || "";
let userData = token ? decode(token) : {};

function AddShipment() {
  const [trackingIdGenerated, setTrackingIdGenerated] = useState("");
  const [maxDate, setMaxDate] = useState(null);
  const [selectedTypeOption, setSelectedTypeOption] = useState(null);
  const [selectedModeOption, setSelectedModeOption] = useState(null);

  const [selectedOriginOption, setSelectedOriginOption] = useState(null);
  const [selectedDestinationOption, setSelectedDestinationOption] =
    useState(null);

  const [whichSubmitClicked, setWhichSubmitClicked] = useState();
  let history = useHistory();

  useEffect(() => {
    setMaxDate(moment().format("YYYY-MM-DD"));
    codeGeneration();
  }, []);
  useEffect(() => {
    const handleClick = (e) => {
      // console.log(e.key)
      if ((e.metaKey && e.key == "Enter") || (e.ctrlKey && e.key == "Enter")) {
        //check form is not empty
        if (checkFormIsNotEmpty() == true) {
          // alert('can be send')s
          dataSendingLogic();
        }
        // alert("command + enter clicked");
      }
    };

    window.addEventListener("keydown", handleClick);

    return () => {
      window.removeEventListener("keydown", handleClick);
    };
  }, []);

  const checkFormIsNotEmpty = () => {
    if (
      document.shipment.trackingId.value != "" &&
      document.shipment.from.value != "" &&
      document.shipment.fromPhone.value != "" &&
      document.shipment.to.value != "" &&
      document.shipment.toPhone.value != "" &&
      document.shipment.productAndQuantity.value != "" &&
      document.shipment.weight.value != "" &&
      document.shipment.noOfPackages.value != "" &&
      document.shipment.shipmentMode.value != "" &&
      document.shipment.shipmentType.value != "" &&
      document.shipment.noOfPackages.value != "" &&
      document.shipment.origin.value != "" &&
      document.shipment.destination.value != "" &&
      document.shipment.receivedAt.value != "" &&
      document.shipment.comments.value != ""&&
      document.shipment.estimatedDeliveryTime.value != ""
      
    ) {
      // alert(

      //   "all values inputed"
      // );
      return true;
    }
    // alert(

    //   "all values are not inputed"
    // );
    return false;

  };
  const dataSendingLogic = () => {
    let data = {
      trackingId: document.shipment.trackingId.value,
      from: document.shipment.from.value,
      fromPhone: document.shipment.fromPhone.value,
      to: document.shipment.to.value,
      toPhone: document.shipment.toPhone.value,
      productAndQuantity: document.shipment.productAndQuantity.value,
      weight: document.shipment.weight.value,
      noOfPackages: document.shipment.noOfPackages.value,
      shipmentMode: document.shipment.shipmentMode.value,
      shipmentType: document.shipment.shipmentType.value,
      noOfPackages: document.shipment.noOfPackages.value,
      origin: document.shipment.origin.value,
      destination: document.shipment.destination.value,
      receivedAt: document.shipment.receivedAt.value,
      comments: document.shipment.comments.value,
      estimatedDeliveryTime:document.shipment.estimatedDeliveryTime.value,
    };
    authReq("add-shipment", data).then((res) => {
      // console.log(res.data);
      if (res.data.success == true) {
        toastr.success("Added successfuly", "Success");
        //TODO: clear values to add new shipment after 1 second
        setTimeout(() => {

          document.querySelector("form").reset();
          setSelectedTypeOption(null);
          setSelectedModeOption(null);
          setSelectedOriginOption(null);
          setSelectedDestinationOption(null);
          setTrackingIdGenerated("");
          codeGeneration();
          if (whichSubmitClicked == "save") {
            history.push("/shipments");
          }


        }, 1000);
      } else if (res.data.success == false) {
        toastr.error(res.data.message, "Error");
      }
    });
  };
  const addShipmentSubmit = (e) => {
    e.preventDefault();
    dataSendingLogic();
  };
  async function codeGeneration() {
    // console.log("inside codeGeneration");
    authReq("generate-tracking-id").then(({ data }) => {
      if (data.success == true) {
        setTrackingIdGenerated(data.trackingId);
      }
    });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Management" breadcrumbItem="Add Shipment" /> */}
          <Form name="shipment" onSubmit={addShipmentSubmit}>
            <div className="custom-form-tracking-grouping">
              <h4 className="custom-form-grouping-header">Tracking Details</h4>
              <div className="row">
                <FormGroup className="col-md-6">
                  <Label htmlFor="trackingId">Tracking Id</Label>
                  <Input
                    id="trackingId"
                    name="trackingId"
                    type="text"
                    className="form-control"
                    defaultValue={trackingIdGenerated}
                    onChange={(e) => {
                      setTrackingIdGenerated(e.target.value);
                    }}
                    required
                  />
                </FormGroup>
              </div>
            </div>
            <div className="custom-form-send-receiver-grouping">
              <h4 className="custom-form-grouping-header">
                Sender / Receiver details
              </h4>
              <div className="row">
                {" "}
                <div className="col-md-6">
                  <FormGroup>
                    <Label htmlFor="from">Sender Address</Label>
                    <Input
                      id="from"
                      name="from"
                      type="textarea"
                      className="form-control"
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="fromPhone">Sender Phone No.</Label>
                    <Input
                      id="fromPhone"
                      name="fromPhone"
                      type="number"
                      className="form-control"
                      required
                      max="999999999999999"
                      maxLength="15"
                      onKeyDown={(e) => {
                        if (
                          e.target.value.length == 15 &&
                          e.key >= 0 &&
                          e.key <= 9
                        ) {
                          e.preventDefault();
                        }
                        if (e.key == "ArrowUp" || e.key == "ArrowDown")
                          e.preventDefault();
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup>
                    <Label htmlFor="to">Receiver Address</Label>
                    <Input
                      id="to"
                      name="to"
                      type="textarea"
                      className="form-control"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="toPhone">Receiver Phone No.</Label>
                    <Input
                      id="toPhone"
                      name="toPhone"
                      type="number"
                      className="form-control"
                      required
                      max="999999999999999"
                      maxLength="15"
                      onKeyDown={(e) => {
                        if (
                          e.target.value.length == 15 &&
                          e.key >= 0 &&
                          e.key <= 9
                        ) {
                          e.preventDefault();
                        }
                        if (e.key == "ArrowUp" || e.key == "ArrowDown")
                          e.preventDefault();
                      }}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="custom-form-grouping">
              <h4 className="custom-form-grouping-header">Shipment details</h4>
              {/* <div className="custom-form-section1 "> */}
              <div className="row">
                <FormGroup className="col-md-6">
                  <Label htmlFor="productAndQuantity">
                    Products & Quantity
                  </Label>
                  <Input
                    id="productAndQuantity"
                    name="productAndQuantity"
                    type="textarea"
                    className="form-control"
                    required
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="weight">Weight</Label>
                  <Input
                    id="weight"
                    name="weight"
                    type="text"
                    className="form-control"
                    required
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="no">No. of Packages</Label>
                  <Input
                    id="noOfPackages"
                    name="noOfPackages"
                    type="text"
                    className="form-control"
                    required
                    pattern="[0-9]+"
                  />
                </FormGroup>
              </div>

              <div className="row">
                <FormGroup className="col-md-3">
                  <Label htmlFor="modeofShipment">Mode of Shipment</Label>
                  <Select
                    name="shipmentMode"
                    id="shipmentMode"
                    value={selectedModeOption}
                    onChange={setSelectedModeOption}
                    required
                    options={modeOptions}
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="shipmentType">Type of Shipment</Label>
                  <Select
                    value={selectedTypeOption}
                    onChange={setSelectedTypeOption}
                    name="shipmentType"
                    id="shipmentType"
                    required
                    options={typeOptions}
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="origin">Origin</Label>

                  <Select
                    value={selectedOriginOption}
                    onChange={setSelectedOriginOption}
                    options={countryNames}
                    name="origin"
                    id="origin"
                    required
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="origin">Destination</Label>
                  <Select
                    value={selectedDestinationOption}
                    onChange={setSelectedDestinationOption}
                    options={countryNames}
                    name="destination"
                    id="destination"
                    required
                  />
                </FormGroup>
              </div>
              <div className="row">
                <FormGroup className="col-md-3">
                  <Label htmlFor="receivedAt">Date of Shipment Received</Label>
                  <Input
                    id="receivedAt"
                    name="receivedAt"
                    type="date"
                    className="form-control"
                    required
                    max={maxDate}
                  />
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label htmlFor="estimatedDeliveryTime">Estimated Delivery Time</Label>
                  <Input
                    id="estimatedDeliveryTime"
                    name="estimatedDeliveryTime"
                    type="date"
                    className="form-control"
                    required
                    min={maxDate}
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label htmlFor="comments">Comments</Label>
                  <Input
                    id="comments"
                    name="comments"
                    type="textarea"
                    className="form-control"
                    required
                    rows="1"
                  />
                </FormGroup>
              </div>
              
            </div>

            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                id="save"
                onClick={() => setWhichSubmitClicked("save")}
              >
                Save
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="saveNext"
                onClick={() => setWhichSubmitClicked("saveNext")}
              >
                Save and Add Next
              </button>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddShipment;
