import React, { useState, useEffect, useRef } from "react";
import { useHistory,useParams } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import toastr, { success } from "toastr";
import "toastr/build/toastr.min.css";
import moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Import  StatusDisplay
import StatusDisplay from "../../components/StatusDisplay/StatusDisplay";
import countryNames from "../../data/countryNames";

import "./datatables.scss";
import cookie from "js-cookie";
import decode from "jwt-decode";

import authReq from "funs/authReq";

let token = cookie.get("token") || "";
let userData = token ? decode(token) : {};
function EditGroup() {
  const [csvData, setCsvData] = useState([]);
  const [modal, setModal] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);

  let history =useHistory()
  const { referenceId } = useParams();


 
  const grpCrtEditForm = (e) => {
    e.preventDefault();
    
    // alert("clickedd");
    //get reference id and corresponding tracking ids
    
    let trackingIds=newData.rows.map(item=> {return (item.trackingId)})
    let datatoSend={referenceId,trackingIds}
    authReq("edit-group-shipments",datatoSend).then((res)=>{
      if(res.data.success==true){
        toastr.success("Grouped successfuly", "Success");
        setTimeout(()=>{
          // setNewData(prev=>{
          // return {...prev,rows:[]}

        // })
                  // TODO : return to grup list

        getData();

      },1000)
        
      }
      else if(res.data.success==false)
      {
        toastr.error("Grouping was unsuccessful", "Error");
        
      }
    });
  }
  const addToGroup = (item) => {
    item.remove = (<div className="downloadButtonDiv" onClick={()=>removeFromGroup(item)}>Remove</div>);

    //add to group
    setNewData((prev) => {
      return { ...prev, rows: [...prev.rows, item] };
    });

    //remove from all data
    setData((prev) => {
      let newRows=prev.rows.filter(x=>{
        return x.trackingId!=item.trackingId
      })
      return { ...prev, rows: newRows };
    });
  };

  const removeFromGroup=(item)=>{
    //add to all data
    setData((prev) => {
      return { ...prev, rows: [item,...prev.rows] };
    });
    //remove from group
    setNewData((prev) => {
      let newRows=prev.rows.filter(x=>{
        return x.trackingId!=item.trackingId
      })
      return { ...prev, rows: newRows };
    });
  }
  const formatData=(data)=>{

  }
  const getData = () => {
    authReq("get-group-shipments",{referenceId}).then(({ data }) => {
      let n = [["Voucher Code", "Validity Days"]];
      // console.log(data);
      if(data.success==false){
        // history.push("/list-group")
        toastr.error("Cannot fetch data", "Error");

        return
      }
      let dt = data.shipmentsNotPresent.map((item, index) => {
       
        if(item.referenceId.length==0)
        {
          item.referenceId="-"
        }
        else{
          item.referenceId= item.referenceId.join(", ")
        }
        item.invoice = (
          <div
            className="downloadButtonDiv"
            onClick={() => {
              addToGroup(item);
            }}
          >
            Add
          </div>
        );
        item.status=<StatusDisplay value={item.status}/>
       
        item.origin=countryNames.filter((x) => x.value == item.origin)[0].label
        item.destination=countryNames.filter((x) => x.value == item.destination)[0].label
        item.createdAt = new Date(item.createdAt).toLocaleDateString();
        return item;
      });

      setCsvData(n);
      setData((prev) => {
        return { ...prev, rows: dt };
      });
      let dts = data.shipmentsPresent.map((item, index) => {
       
        if(item.referenceId.length==0)
        {
          item.referenceId="-"
        }
        else{
          item.referenceId= item.referenceId.join(", ")
        }
        item.remove = (<div className="downloadButtonDiv" onClick={()=>removeFromGroup(item)}>Remove</div>);

        item.status=<StatusDisplay value={item.status}/>
        
        item.origin=countryNames.filter((x) => x.value == item.origin)[0].label
        item.destination=countryNames.filter((x) => x.value == item.destination)[0].label
        item.createdAt = new Date(item.createdAt).toLocaleDateString();
        return item;
      });
      setNewData((prev) => {
        return { ...prev, rows: dts };
      });
    });
  };
  const [resellers, setResellers] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const submit = (e) => {
    e.preventDefault();
    // console.log("clic");
    let data = {
      name: document.modalbody.name.value,
      host: document.modalbody.host.value,
      username: document.modalbody.username.value,
      password: document.modalbody.password.value,
    };
    authReq("add-server", data).then((res) => {
      // console.log(res.data)
      toastr.success("Added successfuly", "Success");
      setModal(false);
      getData();
    });
  };
  const tog_modal = () => {
    setModal((prev) => !prev);
  };

  const [newData, setNewData] = useState({
    columns: [
      {
        label: "Tracking Id",
        field: "trackingId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Group Id(s)",
        field: "referenceId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "createdAt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Sender",
        field: "from",
        sort: "asc",
        width: 100,
      },
      {
        label: "Receiver",
        field: "to",
        sort: "asc",
        width: 100,
      },
      {
        label: "Origin",
        field: "origin",
        sort: "asc",
        width: 100,
      },
      {
        label: "Destination",
        field: "destination",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "remove",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  
  const [data, setData] = useState({
    columns: [
      {
        label: "Tracking Id",
        field: "trackingId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Group Id(s)",
        field: "referenceId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "createdAt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Sender",
        field: "from",
        sort: "asc",
        width: 100,
      },
      {
        label: "Receiver",
        field: "to",
        sort: "asc",
        width: 100,
      },
      {
        label: "Origin",
        field: "origin",
        sort: "asc",
        width: 100,
      },
      {
        label: "Destination",
        field: "destination",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "invoice",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Management" breadcrumbItem="Edit Grouping" />
          {userData.role > 1 && (
            <Row className="mb-2">
              <Col sm="12">
                <div className="text-sm-right">
                  <Button
                    onClick={tog_modal}
                    style={{ display: "none" }}
                    type="button"
                    color="success"
                    className="btn-rounded waves-effect waves-light mb-2 mr-2"
                  >
                    <i className="mdi mdi-plus mr-1"></i> Add Server
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-12">
              {/* <Card>
                
                <CardBody>
                  <form onSubmit={grpCrtEditForm}>
                    <h6 htmlFor="referenceId">ReferenceId</h6>
                    <input
                      type="text"
                      id="referenceId"
                      name="referenceId"
                      value={referenceId}
                      placeholder="reference id"
                      required
                      className="createGroupTextbox"
                      disabled={true}
                    />
                    <button type="submit" id="createGp" className="createGroupButton float-md-right" disabled={newData.rows.length <= 0?true:false}>
                      Edit Group
                    </button>
                  </form>
                </CardBody>
              </Card> */}
              <Card className={optionSelected ? "" : "dispNone"}>
                <CardBody>
                <h4 className="custom-form-grouping-header">Group</h4>
                <form onSubmit={grpCrtEditForm} style={{padding:"10px 0px 20px 0px"}}>
                    <h6 htmlFor="referenceId">Group Id</h6>
                    <input
                      type="text"
                      id="referenceId"
                      name="referenceId"
                      value={referenceId}
                      placeholder="group id"
                      required
                      className="createGroupTextbox"
                      disabled={true}

                    />
                    <button type="submit" id="createGp" className="createGroupButton float-md-right" disabled={newData.rows.length <= 0?true:false}>
                      Edit Group
                    </button>
                  </form>
                  <MDBDataTable responsive striped bordered data={newData} />
                </CardBody>
              </Card>
              <Card className={optionSelected ? "" : "dispNone"}>
                <CardBody>
                <h4 className="custom-form-grouping-header">All shipments</h4>

                  <MDBDataTable responsive striped bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <CSVLink
            filename={"vouchers.csv"}
            class="btn btn-primary mb-2"
            data={csvData}
          >
            Download CSV
          </CSVLink> */}
        </div>
      </div>
      <Modal
        size="md"
        isOpen={modal}
        toggle={tog_modal}
        scrollable={true}
        // centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Server</h5>
          <button
            type="button"
            onClick={tog_modal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form name="modalbody" onSubmit={submit}>
            <FormGroup>
              <Label htmlFor="name">Server Name</Label>
              <Input
                id="name"
                name="name"
                type="text"
                className="form-control"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="host">Host</Label>
              <Input
                id="host"
                name="host"
                type="text"
                className="form-control"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="username">Username</Label>
              <Input
                id="username"
                name="username"
                type="text"
                className="form-control"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                name="password"
                type="text"
                className="form-control"
              />
            </FormGroup>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={tog_modal}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
}


export default EditGroup;
